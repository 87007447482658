import React from "react"

const StationsList = props => {

  function dynamicSort(property) {
    var sortOrder = 1

    if (property[0] === "-") {
      sortOrder = -1
      property = property.substr(1)
    }

    return function(a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property])
      } else {
        return a[property].localeCompare(b[property])
      }
    }
  }

  var { stationDetails } = props
  if (props.alphabetize) {
    // all-stations should display in alphabetical order instead of the order in response
    var stationDetails = stationDetails.sort(dynamicSort("title"))
  }

  return (
    <div>
      <div className="stations">
        <div className="stations-row">
          {stationDetails.map((station, index) => (
            <div className="station" key={index}>
              <a
                href={station.link.urls.web}
                deeplink={station.link.urls.device}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div
                  className="logo waves-effect waves-light waves-button"
                  style={{
                    backgroundColor:
                      station.background_color === ""
                        ? "#ccc"
                        : station.background_color,
                  }}
                >
                  <div style={{ width: "100%", paddingBottom: "100%" }}>
                    <img
                      src={station.img_uri + "?ops=fit(160%2C%20160)"}
                      className="station-thumb"
                      alt={station.title}
                      loading="lazy"
                      title={station.title}
                    />{" "}
                  </div>
                </div>
                <p>{station.subtitle}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="clearfix"> </div>
    </div>
  )
}

export default StationsList
